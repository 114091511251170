import React, { createContext, ReactNode, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { CreateNewUserDto } from '../dtos/create-new-user'
import { User } from '../entities/user'
import { api } from '../service/api'
import { useAuth } from './use-auth'

interface UserContext {
    getUser(): Promise<User | undefined>
    createUser(data: CreateNewUserDto): Promise<User | undefined>
    user: User | undefined
}

interface Props {
    children: ReactNode
}

const UserContext = createContext({} as UserContext)

export function UserProvider({ children }: Props) {
    // hooks
    const { token } = useAuth()

    // state
    const [user, setUser] = useState()

    async function getUser() {
        try {
            const response = await api.get('/users/me', {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            setUser(response.data)
            return response.data
        } catch (error) {
            localStorage.removeItem('@ada_admin:token')
            window.location.reload()
            return undefined
        }
    }

    async function createUser(data: CreateNewUserDto) {
        try {
            const response = await api.post('/admins/users', data, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            setUser(response.data)
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    return (
        <UserContext.Provider value={{ getUser, user, createUser }}>
            {children}
        </UserContext.Provider>
    )
}

export function useUser(): UserContext {
    const context = useContext(UserContext)
    if (!context) {
        throw new Error('useUser must be within a provider')
    }
    return context
}
